import {defineStore} from 'pinia';
import loanApi from "@/apis/loan-api";

export const companyStore = defineStore({
    id: 'companyStore',
    state: () => ({
        company: {}
    }),
    actions: {
        async getCompany(application_id, parent = null) {
            if (parent != null) parent.$isLoading(true);
            await loanApi.getCompanyByAppID(application_id).then(response => {
                const {status, data} = response;
                if (status === "success") {
                    const {company} = data;
                    this.company = company;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                if (parent != null) parent.$isLoading(false);
            });
        }
    }
});
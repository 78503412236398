import {defineStore} from 'pinia'
import {alertStore} from "@/store/alert";
import loanApi from "@/apis/loan-api"
import {companyStore} from "./company";
import {statusStore} from "./status";


const initGrossSales = {
    _2019: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
    _2020: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
    _2021: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
}

const initHealthExpenses = {
    _2020: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
    _2021: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
}

const initCobraExpenses = {
    _2020: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
    _2021: {
        q1: 0, q2: 0, q3: 0, q4: 0
    },
}


const initPayrolls = {
    'index': 0,
    'name': '',
    'date': '',
    'quarter': '',
    'amount': '',
    'type': '',
    'outOfRange': false,
    'keep': true,
    'familyMember': false,
    'bindIdentifier': ''
}

export const userStore = defineStore({
    id: 'userStore',
    state: () => ({
        currentUser: JSON.parse(localStorage.getItem('userdata')) ?? null,
        appID: 0,
        grossSales: {...initGrossSales},
        healthExpenses: {...initHealthExpenses},
        cobraExpenses: {...initCobraExpenses},
        owners: [{"owner":"","percentage":0,"checked":false}], // from the people selected on question 9
        question: {},


        userFiles: [],
        uploadReady: false,
        uploadStatus: false,
        upload2020: true,
        upload2021: true,
        apiError: '',
        validExtensions: [
            'xls',
            'csv',
            'xlsx'
        ],
        formAnswers: {
            grossSales: [],
        },
    }),
    actions: {
        async saveGrossSales(changeStatus) {
            const alertState = alertStore()
            changeStatus(true)
            await loanApi.saveGrossSales(this.appID, this.grossSales).then((response) => {
                const {status, message} = response;
                if (status === "success") {
                    alertState.setAlert({message: message, errors: null, color: 'success'})
                } else
                    alertState.setAlert({message: message, errors: null, color: 'danger'})
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                changeStatus(false)
            });
        },

        async saveCobraHealth(changeStatus) {
            const alertState = alertStore()
            changeStatus(true)
            await loanApi.saveCobraHealth(this.appID, this.healthExpenses, this.cobraExpenses)
                .then((response) => {
                    const {status, message} = response;
                    if (status === "success") {
                        alertState.setAlert({message: message, errors: null, color: 'success'})
                    } else
                        alertState.setAlert({message: message, errors: null, color: 'danger'})
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    changeStatus(false)
                });
        },

        async getQuestion(changeStatus) {
            changeStatus(true)
            await loanApi.getQuestion(this.appID).then((response) => {
                const {status, data} = response;
                if (status === "success") {
                    const {gross_sales, cobra, health, company, question, payrolls, family_members} = data;
                    this.grossSales = gross_sales === ""? {...initGrossSales} : JSON.parse(gross_sales);
                    this.cobraExpenses = cobra === ""? {...initCobraExpenses} : JSON.parse(cobra)
                    this.healthExpenses = health === ""? {...initHealthExpenses} : JSON.parse(health)
                    this.question = JSON.parse(question)
                    this.owners = this.question.q9.members
                    const cStore = companyStore()
                    cStore.company = company
                    const status = statusStore()
                    status.familyOwners.list1 = family_members === ""? [] : JSON.parse(family_members)
                    status.savedPayroll = payrolls === ""? [{...initPayrolls}] : JSON.parse(payrolls)
                    status.payroll = status.savedPayroll
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                changeStatus(false)
            });
        },


        async savePayrolls(changeStatus) {
            const alertState = alertStore()
            const statusObj = statusStore();
            changeStatus(true)

            const uniqueNames = [...new Set(statusObj.payroll.map(item => item.name))];
            let family_members = uniqueNames.map((item) => {
                if (item !== "") return {"name": item, "checked": false}
            })

            if (family_members[0] === undefined)
                family_members = []

            await loanApi.savePayrolls(this.appID, statusObj.payroll, family_members)
                .then((response) => {
                    const {status, message} = response;
                    if (status === "success") {
                        statusObj.savedPayroll = statusObj.payroll
                        statusObj.payrollClear = false
                        statusObj.familyOwners.list1 = family_members
                        alertState.setAlert({message: message, errors: null, color: 'success'})
                    } else
                        alertState.setAlert({message: message, errors: null, color: 'danger'})
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    changeStatus(false)
                });
        },


        uploadFiles(data, folder) {
            console.log(data, folder);
        },
        deleteFile(data) {
            console.log(data);
        },
        savePayroll(uuid, data) {
            console.log(uuid, data);
        },
        saveSheetData(uuid, data) {

            console.log(uuid, data);
        },
        saveSummaryData(uuid, data) {
            console.log(uuid, data);
        },
        upload941(data, folder, year) {
            this.uploadStatus = false;
            console.log(data, folder);
            console.log(year);
        }
    }
})



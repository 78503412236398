import { defineStore } from 'pinia'

export const statusStore = defineStore({
    id: 'statusStore',
    state: () => ({
        payrollClear: false,
        payroll: [],
        savedPayroll: [],
        health: [],
        retirement: [],
        fmla: [],

        summary: [],
        dupsArray: [],
        duplicated: [],
        similars: [],

        familyOwners: {
            list1: [],
            list2: [
                { name: "Stephens", checked: false },
            ],
            list3: [
                { name: "Steve Smith", checked: false },
            ]
        }
    }),
    actions: {
        getSavedPayroll(uuid) {
            console.log(uuid);
        },
        OnClickedCheckAll(type) {
            switch (type){
                case 1:
                    this.familyOwners.list1.map((item) => {
                        item.checked = !item.checked;
                    });
                    break;
                case 2:
                    this.familyOwners.list2.map((item) => {
                        item.checked = !item.checked;
                    })
                    break;
                case 3:
                    this.familyOwners.list3.map((item) => {
                        item.checked = !item.checked;
                    })
                    break;
                default:
            }
        },
    }
})